import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../components/LayoutDAI'
import Section from '../../components/dai/Section'
import Card from '../../components/dai/Card'
import { Heading1 } from '../../components/dai/Heading1'
import { Heading2 } from '../../components/dai/Heading2'
import { Text } from '../../components/dai/Text'
import Columns from '../../components/dai/Columns'
import Column from '../../components/dai/Column'
import CalloutCard from '../../components/dai/CalloutCard'
import IconCard from '../../components/dai/IconCard'
import { Button } from '../../components/dai/Button'
import LinkCTA from '../../components/dai/LinkCTA'
import { BoldText } from '../../components/dai/BoldText'

import SEO from '../../components/SEO'
import Space from '../../components/common/Space'

import PhotoVideo from '../../images/dai/icons/assets/photo-video.svg'
import BrokenComputer from '../../images/dai/icons/assets/broken-computer.svg'
import TestFailed from '../../images/dai/icons/assets/test-failed.svg'

import ShoppingCart from '../../images/dai/icons/assets/shopping-cart.svg'
import Coins from '../../images/dai/icons/assets/coins.svg'
import Globe from '../../images/dai/icons/assets/globe.svg'
import Page from '../../images/dai/icons/assets/page.svg'
import ShieldCheckmark from '../../images/dai/icons/assets/shield-checkmark.svg'
import Capital from '../../images/dai/icons/assets/capital.svg'

export default function DigitalAccessibilityIndex() {
  return (
    <LayoutDAI hideTableOfContents customSEO>
      <SEO
        title="Enterprise Site Accessibility | 2023 Digital Accessibility Index"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        desc="We scanned 40,000 enterprise sites to measure the accessibility of the world’s largest brands. Here are the biggest roadblocks for people with disabilities."
        bannerUrl="https://images.prismic.io/audioeye-web/f8f386b0-3ca9-4008-99e0-0577fad6d699_digital-accessibility-index.jpg?auto=compress,format"
        imageAlt="Flustered man standing outside his car at the edge of a cliff, blocking him from being able to continue on the path."
        noIndex
      />
      <Section
        paddingTop="lg"
        constraint="xl"
        innerConstraint="md"
        gutters="lg"
        alignCenter
        backgroundImage="/images/dai/landing-page-hero-desktop.svg"
        backgroundImageMobile="/images/dai/mobile-landing-background.svg"
      >
        <Heading1 heading1>2023 Digital Accessibility Index</Heading1>
        <Space height={16} />
        <Text largeBody>
          We scanned almost 40,000 enterprise websites to measure the accessibility of the world’s largest brands. Then
          we worked with members of the disability community to test key pages on the top sites in industries like
          retail, finance, and travel.
        </Text>
        <Space height={32} />
        <Text largeBody>Here are the biggest roadblocks for people with disabilities.</Text>
        <Space height={40} />
        <Button
          aria-label="Start Reading the digital accessibility index"
          text="Start Reading"
          to="/digital-accessibility-index/executive-summary"
          buttonType="primary"
        />
      </Section>
      <Section constraint="xl" gutters="lg" alignCenter>
        <CalloutCard higherPosition largeCard>
          <Text largeCapsInter color="orange900">
            KEY FINDINGS
          </Text>
          <Space height={32} />
          <Columns unorderedList>
            <Column size={8} tabletSize={12} listItem>
              <IconCard noCard>
                <PhotoVideo />
                <Space height={16} />
                <Text xLargeBodyBold>56%</Text>
                <Space height={16} />
                <Text smallBodyMedium>
                  <BoldText>of images are not accessible</BoldText> to people with visual impairments, which can prevent
                  them from being able to understand or benefit from any information conveyed by the image.
                </Text>
              </IconCard>
            </Column>
            <Column size={8} tabletSize={12} listItem>
              <IconCard noCard>
                <BrokenComputer />
                <Space height={16} />
                <Text xLargeBodyBold>64%</Text>
                <Space height={16} />
                <Text smallBodyMedium>
                  <BoldText>of pages have links that are not clear</BoldText> to people with some visual and cognitive
                  impairments, which can make it difficult for them to navigate between pages or find the information
                  they need.
                </Text>
              </IconCard>
            </Column>
            <Column size={8} tabletSize={12} listItem>
              <IconCard noCard>
                <TestFailed />
                <Space height={16} />
                <Text xLargeBodyBold>25%</Text>
                <Space height={16} />
                <Text smallBodyMedium>
                  <BoldText>of forms are missing clear labels</BoldText>, which can make it difficult for people with
                  disabilities to know what to enter into each field — impacting key tasks like checkout or account
                  creation.
                </Text>
              </IconCard>
            </Column>
          </Columns>
        </CalloutCard>
      </Section>
      <Section paddingTop="lg" constraint="xl" innerConstraint="md" gutters="lg">
        <Heading2 heading2>Digital accessibility can be a bumpy road.</Heading2>
        <Space height={24} />
        <Text>Whether we’re shopping online or reading the news, we all count on websites that just work.</Text>
        <Space height={32} />
        <Text>
          Unfortunately, digital experiences are often broken for the 1.3 billion people globally who live with a
          disability. Even when brands try to build inclusive websites and mobile apps, accessibility issues can slip
          through the cracks — making it difficult for disabled users to accomplish key tasks online.
        </Text>
        <Space height={32} />
        <Text>
          Keep reading to learn the most common accessibility issues uncovered by the{' '}
          <BoldText>Digital Accessibility Index</BoldText> — including what our expert testers found after manually
          auditing the top sites in key industries.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="xl" gutters="lg">
        <Columns unorderedList>
          <Column size={8} listItem>
            <Card
              image={
                <StaticImage
                  src="../../images/dai/card/key-insights.png"
                  alt="Three drop pins with icons representing inaccessible images, links, and keyboard traps."
                />
              }
              cta={
                <LinkCTA
                  aria-label="Learn More about the digital accessibility index key insights"
                  text="Learn More"
                  to="/digital-accessibility-index/key-insights/"
                />
              }
            >
              <Text heading3 className="asH4">
                Key Insights
              </Text>
              <Space height={16} />
              <Text smallBody>
                Discover the biggest trends in digital accessibility — and how these issues affect people with
                disabilities.
              </Text>
            </Card>
          </Column>
          <Column size={8} listItem>
            <Card
              image={
                <StaticImage
                  src="../../images/dai/card/industry-reports.png"
                  alt="Four Billboards featuring industries: insurance, travel, retail, and financial services."
                />
              }
              cta={
                <LinkCTA
                  aria-label="Learn More about the digital accessibility index specific industry reports"
                  text="Learn More"
                  to="/digital-accessibility-index/industry-reports/"
                />
              }
            >
              <Text heading3 className="asH4">
                Industry Reports
              </Text>
              <Space height={16} />
              <Text smallBody>
                Dive into key accessibility issues by industry, based on our expert audit of the top sites in each
                vertical.
              </Text>
            </Card>
          </Column>
          <Column size={8} listItem>
            <Card
              image={
                <StaticImage
                  src="../../images/dai/card/overall-results.png"
                  alt="A collage of roadsigns with data visualization instead of directions"
                />
              }
              cta={
                <LinkCTA
                  aria-label="Learn More about the digital accessibility index methodology"
                  text="Learn More"
                  to="/digital-accessibility-index/methodology/"
                />
              }
            >
              <Text heading3 className="asH4">
                Methodology
              </Text>
              <Space height={16} />
              <Text smallBody>
                Learn how we created the Index — plus the most common accessibility issues across all industries.
              </Text>
            </Card>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" constraint="xl" innerConstraint="md">
        <Heading2 heading2>Start with your industry</Heading2>
        <Space height={32} />
        <Text>
          Learn what expert audits of the top 3-5 companies in each industry revealed about the user experience for
          people with disabilities.
        </Text>
        <Space height={32} />
      </Section>
      <Section paddingBottom="xl" constraint="xl">
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard flex>
              <ShoppingCart />
              <Space height={16} />
              <LinkCTA text="Retail" to="/digital-accessibility-index/industry-reports/retail/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Coins />
              <Space height={16} />
              <LinkCTA text="Financial Services" to="/digital-accessibility-index/industry-reports/finance/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Globe />
              <Space height={16} />
              <LinkCTA text="Travel" to="/digital-accessibility-index/industry-reports/travel/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Page />
              <Space height={16} />
              <LinkCTA text="Media" to="/digital-accessibility-index/industry-reports/media/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <ShieldCheckmark />
              <Space height={16} />
              <LinkCTA text="Insurance" to="/digital-accessibility-index/industry-reports/insurance/" />
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard flex>
              <Capital />
              <Space height={16} />
              <LinkCTA text="Government" to="/digital-accessibility-index/industry-reports/government/" />
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="xl" backgroundColor="orange300">
        <Columns>
          <Column size={12}>
            <Text heading3>Ready, Set, Go</Text>
            <Space height={24} />
            <Text>
              Keep reading to learn how we developed this Index, the most common barriers by industry, and how each one
              impacts people with disabilities.
            </Text>
          </Column>
          <Column size={12} justifyCenter alignItemsEnd>
            <Button text="Start Reading" to="/digital-accessibility-index/executive-summary" size="small" />
          </Column>
        </Columns>
      </Section>
    </LayoutDAI>
  )
}
