/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'

import Space from '../common/Space'

const Wrapper = styled.div`
  border-radius: 16px;
  background-color: ${props => props.theme.color.orange300};
  width: 100%;
  max-width: 360px;
  height: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const Image = styled.div`
  img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    width: 100%;
  }
`

const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Card = ({ children, image, cta }) => (
  <Wrapper>
    <Image>{image}</Image>
    <Content>
      <div>{children}</div>
      <div>
        <Space height={32} />
        {cta}
      </div>
    </Content>
  </Wrapper>
)

export default Card
