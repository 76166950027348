/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import { theme } from '../../styles'

import chevronPurple from '../../../static/icons/arrow-icons/chevron-icon-right-purple500.svg'
import rightArrowPurple from '../../../static/icons/arrow-icons/right-arrow-icon-purple500.svg'

const Wrapper = styled(Link)`
  background-color: transparent;
  border-color: transparent;
  padding: 0px 0px;
  background-image: none !important;
  text-decoration: none !important;
  color: ${theme.color.purple500};
  font-size: ${theme.fonts.size.smallBody};
  font-weight: 700;

  ::before {
    transition: all 100ms ease-in-out;
    content: '';
    position: absolute;
    background-image: url(${chevronPurple});
    background-position: center;
    top: 0;
    bottom: 0;
    left: 100%;
    background-repeat: no-repeat;
    right: 0;
    width: 20px;
    margin-top: 1px;
  }

  :hover,
  :focus {
    border-color: transparent;
    background-color: transparent;
    color: ${theme.color.purple500};

    ::before {
      background-image: url(${rightArrowPurple});
      width: 28px;
    }
  }
  :focus {
    padding: 3px 28px 3px 3px !important;

    ::before {
      left: unset;
    }
  }
  @media (max-width: 1050px) {
    width: auto !important;
    margin: 0 auto;
  }
`

const LinkCTA = ({ text, to, ...props }) => (
  <Wrapper {...props} to={to} className="button">
    {text}
  </Wrapper>
)

export default LinkCTA
